import React from "react";
import { Fade } from "react-awesome-reveal";
import Whats from "./Whats";
import "../styles/Services.scss";
import img1 from "../images/services/1.png";
import img2 from "../images/services/2.png";
import img3 from "../images/services/3.png";
import img4 from "../images/services/4.png";

const Services = () => {
  const servicios = [
    {
      id: 1,
      imagen: img1,
      titulo: "Creación de Contenido para Redes Sociales",
      texto:
        'En Ovit Soluciones Web, entendemos la importancia de una presencia constante en facebook e instagram. Por eso, ofrecemos paquetes de creación de contenido desde $399 mensuales, con publicaciones diarias que ayudan a generar credibilidad y confianza a tus clientes. Este servicio está diseñado para mantener a tu audiencia comprometida y atraer tráfico a tu página. Al publicar regularmente contenido relevante y atractivo, no solo aumentas tu visibilidad, sino que también construyes una imagen sólida que inspira confianza y motiva a tus visitantes a convertir en clientes.',
    },
    {
      id: 2,
      imagen: img2,
      titulo: "Desarrollo de Landing Page",
      texto:
        "Una landing page bien diseñada es una herramienta poderosa para convertir visitantes en clientes. Te ayudamos a crear una página de aterrizaje profesional, optimizada para aumentar tus conversiones y generar más ventas. Con un diseño atractivo y un enfoque claro en lo que realmente importa, guiamos a tus usuarios hacia la acción que deseas. Este servicio te ayudará a destacar frente a tu competencia y a crear una experiencia única para tus potenciales clientes. Aprovecha la oportunidad de transformar tu presencia online. Desde $2500. ¡Haz clic para más información y comienza hoy!",
    },
    {
      id: 3,
      imagen: img3,
      titulo: "Desarrollo de Páginas Web",
      texto:
        "Tu página web es el primer paso para expandir tu negocio en línea y abrir nuevas oportunidades. Con una página profesional y funcional, no solo tendrás una presencia sólida, sino que también podrás integrar soluciones adicionales como sistemas automatizados, tiendas en línea, herramientas de gestión y mucho más. En Ovit Soluciones Web, diseñamos sitios web adaptados a tus necesidades, fáciles de usar y listos para escalar a medida que tu negocio crece. Con nosotros, tu página web será mucho más que un sitio estático; será una herramienta dinámica para el éxito de tu negocio.",
    },
    {
      id: 4,
      imagen: img4,
      titulo: "Aplicaciónes Web Personalizadas",
      texto:
        "En Ovit Soluciones Web, desarrollamos aplicaciones web personalizadas que se adaptan completamente a las necesidades de tu negocio. Ya sea para optimizar procesos internos, mejorar la interacción con tus clientes o integrar sistemas específicos, nuestras soluciones están diseñadas para ofrecerte un alto rendimiento y escalabilidad. Con un enfoque en la funcionalidad, la seguridad y la experiencia del usuario, nuestras aplicaciones web te permiten llevar tu negocio al siguiente nivel. Trabajamos contigo para crear una solución única que resuelva tus desafíos y mejore la eficiencia de tus operaciones.",
    },
  ];

  return (
    <div id="allServices">
      <h4 className="fw-bold mt-5 fs-3 title">NUESTROS SERVICIOS</h4>
      {servicios.map((servicio) => (
        <section
          className="cursosContainer"
          id={"servicio" + servicio.id}
          key={servicio.id}
        >
          <div className="imgContainer text-center">
            <Fade duration="1000">
              <img src={servicio.imagen} alt={servicio.titulo} />
            </Fade>
          </div>
          <div className="infoCursoContainer">
            <Fade duration="1000">
              <span className="titleService">
                <strong>{servicio.titulo}</strong>
              </span>
            </Fade>
            <span className="textService">{servicio.texto}</span>

            <div className="btnServiceContainer">
              <Whats
                classStyle="btn btnService"
                phoneNumber="5215588093678"
                message={`¡Me pudiera brindar más información acerca de ${servicio.titulo}!`}
                text="¡Más información!"
              />
            </div>
          </div>
        </section>
      ))}
    </div>
  );
};

export default Services;

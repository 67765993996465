import React, { useState, useEffect } from 'react';
import { Fade } from "react-awesome-reveal";
import "../styles/Carousel.scss";
import Whats from './Whats';
import img1 from "../images/carousel/1.png"
import img2 from "../images/carousel/2.png"
import img3 from "../images/carousel/3.png"
import img4 from "../images/carousel/4.png"

const Carousel = () => {

    const [anos, setAnos] = useState(0);
    const [reparaciones, setReparaciones] = useState(0);
    const [clientes, setClientes] = useState(0);

    let anosMax=10
    let reparacionesMax=800
    let clientesMax=400

    useEffect(() => {
      const intervalId1 = setInterval(() => {
        if (anos < anosMax) {
          setAnos(anos + 1);
        } else {
          clearInterval(intervalId1);
        }
      }, 10);

      return () => clearInterval(intervalId1);
    }, [anos]);

    useEffect(() => {
        const intervalId2 = setInterval(() => {
          if (reparaciones < reparacionesMax) {
            setReparaciones(reparaciones + 10);
          } else {
            clearInterval(intervalId2);
          }
        }, 5);
  
        return () => clearInterval(intervalId2);
      }, [reparaciones]);

      useEffect(() => {
        const intervalId3 = setInterval(() => {
          if (clientes < clientesMax) {
            setClientes(clientes + 5);
          } else {
            clearInterval(intervalId3);
          }
        }, 10);
  
        return () => clearInterval(intervalId3);
      }, [clientes]);

  return (
    <div className="mb-5 carouselContainer" id="inicio">
        <div className="carouselText">
            <Fade><h1 className="title1 mt-3"><b>Desarrollo de Aplicaciones Web, Páginas Web y Landing Pages</b></h1></Fade>
            <h2 className="mb-3 title2">"Innovadoras soluciones para Potenciar tu Presencia en Línea y Aumentar tus Ventas"</h2>
            <span className="firstText">En Ovit Soluciones Web, somos un equipo de expertos dedicados a ofrecer soluciones digitales innovadoras. Nos especializamos en aplicaciones web personalizadas, creación de contenido para redes sociales, desarrollo de páginas web y landing pages optimizadas. Ofrecemos aplicaciones web a medida para diferentes nichos de negocio, como menús digitales para restaurantes, mejorando la experiencia del cliente, soluciones a medida para aumentar la eficiencia y automatizar procesos en su empresa. Trabajamos de cerca con cada cliente para crear soluciones personalizadas que fortalezcan su presencia en línea y aumenten sus ventas.</span>
            <Fade><div className="container containerDatosMain mt-4 mb-3">
                <div className="containerDatos">
                    <span className="text-center contadorNum fw-bold" id="anosExp">{"+"+anos}</span><br/>
                    <span className="contadorNumText fw-bold">Años de experiencia</span>
                </div>
                <div className="containerDatos">
                    <span className="contadorNum fw-bold" id="reparaciones">{"+"+reparaciones}</span><br/>
                    <span className="contadorNumText fw-bold">Proyectos completados</span>
                </div>
                <div className="containerDatos">
                    <span className="contadorNum fw-bold" id="clientes">{"+"+clientes}</span><br/>
                    <span className="contadorNumText fw-bold">Clientes satisfechos</span>
                </div>
            </div></Fade>
            
            <Fade cascade="true" direction="left"><div className="mt-4 container containerBtn1">
               <Whats classStyle="col-sm-6 mb-2 fw-bold btn btnCarousel" phoneNumber="5215588093678" message="Hola, quiero más información acerca de sus servicios." text="CONTÁCTANOS" />
            </div></Fade>
        </div>
               
        <div className="carouselImages">
            <div id="carouselExampleAutoplaying" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner imgBox">
                    <div className="carousel-item active">
                        <center><img src={img1} className="d-block w-100 " alt="Creación de Contenido para Redes Sociales"/></center>
                    </div> 
                    <div className="carousel-item">
                        <center><img src={img2} className="d-block w-100 " alt="Desarrollo de Landing Page"/></center>
                    </div> 
                    <div className="carousel-item">
                        <center><img src={img3} className="d-block w-100 " alt="Desarrollo de Páginas Web"/></center>
                    </div> 
                    <div className="carousel-item">
                        <center><img src={img4} className="d-block w-100 " alt="Aplicaciónes Web Personalizadas"/></center>
                    </div> 
                </div>
            </div> 
        </div>
    </div>
  );
};

export default Carousel;

import React from "react";
import { Fade } from "react-awesome-reveal";
import "../styles/Footer.scss";
import facebook from "../images/redes/Caralibro.svg";
import appStore from "../images/App.svg";
import logo from "../images/logoHF.png";

const Footer = () => {
  return (
    <footer id="contacto">
      <div className="containerFooter">
        <div className="column1">
          <a href="./index.php">
            <img src={logo} alt="Logo Ovit Soluciones Web México" />
          </a>
          <div className="containerTextColumn1">
            <p>
            En Ovit Soluciones Web, nos especializamos en el desarrollo de páginas web, aplicaciones personalizadas y la creación de contenido para redes sociales. Nuestra misión es impulsar tu presencia online y mejorar tus ventas con soluciones digitales innovadoras y a medida.
            </p>
          </div>
        </div>

        <div className="column2">
          <div>
            <p>NOSOTROS</p>
            <div className="textColumn2">
              <a href="#inicio">
                <span>Conócenos</span>
              </a>
              <br />
              <a href="#porqueE">
                <span>¿Porque elegirnos?</span>
              </a>
              <br />
            </div>
          </div>
          <br />
          <div>
            <p>CONTACTO</p>
            <div className="textColumn2">
              <a href="mailto:info@ovitsolucionesweb.com">
                <span>Envíanos un email</span>
              </a>
              <br />
              <a href="tel:+5215588093678">
                <span>5588093678</span>
              </a>
            </div>
          </div>
        </div>

        <div className="column3">
          <div>
            <p>SERVICIOS</p>
            <div className="textColumn3">
              <a href="#servicio1">
                <span>Creación de Contenido</span>
              </a>
              <br />
              <a href="#servicio2">
                <span>Landing Page</span>
              </a>
              <br />
              <br />
              <span className="ubicacionTitle">REGISTRATE</span>
              <br />
              <a href="#formulario">
                <span id="ubicacion">
                Dejanos tus datos
                </span>
              </a>
              <br />
            </div>
          </div>
        </div>

        <div className="column4">
          <div>
            <p>APPS</p>
            <div>
              <span>Proximamente</span>
            </div>
            <div className="containerImgApp">
              <img src={appStore} alt="" />
            </div>
          </div>

          <div className="containerRedes">
            <Fade cascade="true" direction="left">
              <a href="https://www.facebook.com/devpweb">
                <img src={facebook} alt="Facebook Ovit Soluciones Web" />
              </a>
            </Fade>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from 'react';

const Whats = ({ phoneNumber, message, classStyle, text, iconFont }) => {
  const handleClick = () => {
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <button className={classStyle} onClick={handleClick}>
      {iconFont === "true" ? <><i className='fa-brands fa-whatsapp'></i> {text}</> : text}
    </button>
  );
};

export default Whats;

